import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import HouseIcon from '@material-ui/icons/House'
import CropSquareIcon from '@material-ui/icons/CropSquare'
import KingBedIcon from '@material-ui/icons/KingBed'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import BarChart from '@material-ui/icons/BarChart'


const useStyles = makeStyles(theme => ({
    typeColumn: {
        width: "14rem",
        //marginTop: "2rem"
    },
    surfaceRow: {
        width: "10rem"
    },
    critereContainer: {
        position: "relative",
        width: "50rem",
        marginTop: "2rem",
        borderTop: `2px solid silver`,
        paddingTop: "2rem",
        paddingBottom: "3rem",
        [theme.breakpoints.down('xs')]: {
            width: '25rem'
        }
    },
    dimensionColumn: {
        width: "14rem",

    },
    icon: {
        marginRight: "0.4rem"
    },
    critereButton: {
        position: "absolute",
        bottom: 0,
        right: "1%"
    },
    critereitemtemContainer: {
        marginTop: "1.5rem",
        [theme.breakpoints.down('xs')]: {
            width: '25rem'
        }
    },
    critereTitle: {
        borderLeft: `5px solid ${theme.palette.secondary.main}`,
        paddingLeft: "1rem"
    }
}))

export default function Critere({ city, type, piece, surfaceHabitable, terrain, chambre, diagnostic }) {
    const classes = useStyles()
    const theme = useTheme()

    // const matchesXS = useMediaQuery((theme) => theme.breakpoints.down('xs'))

    return (
        <Grid item container classes={{ root: classes.critereContainer }} direction='column'>
            <Grid item classes={{ root: classes.critereTitle }}>
                <Typography variant='h1' style={{ color: `${theme.palette.primary.main}`, fontWeight: "bold" }}>Critères</Typography>
            </Grid>
            <Grid
                item
                container
                justifyContent='space-between'
                classes={{ root: classes.critereitemtemContainer }}
                alignContent='center'
                direction='row'>
                <Grid item container spacing={1} classes={{ root: classes.typeColumn }} direction='column'>
                    <Grid item alignItems='center' container >
                        <Grid item>
                            <HouseIcon classes={{ root: classes.icon }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>{type}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item alignItems='center' container >
                        <Grid item>
                            <CropSquareIcon classes={{ root: classes.icon }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>{piece} pièces</Typography>
                        </Grid>
                    </Grid>
                    {type === "Maison" && (
                            <Grid item alignItems='center' container >
                                <Grid item>
                                    <BarChart classes={{ root: classes.icon }} />
                                </Grid>
                                <Grid item>
                                    <Typography style={{ fontSize: "1rem" }}>De classe énergétique {diagnostic}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
                <Grid item container spacing={1} classes={{ root: classes.dimensionColumn }} direction='column'>
                    <Grid item alignItems='center' container >
                        <Grid item>
                            <HouseIcon classes={{ root: classes.icon }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>Surface de {surfaceHabitable} m²</Typography>
                        </Grid>
                    </Grid>
                    <Grid item alignItems='center' container >
                        <Grid item>
                            <LocationOnOutlinedIcon classes={{ root: classes.icon }} style={{ marginRight: "0.2rem" }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>{city}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container spacing={1} classes={{ root: classes.dimensionColumn }} direction='column'>
                    <Grid item alignItems='center' container >
                        <Grid item>
                            <KingBedIcon classes={{ root: classes.icon }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>{chambre} chambres</Typography>
                        </Grid>
                    </Grid>
                    {type === "Maison" ? (<Grid item alignItems='center' container >
                        <Grid item>
                            <CropSquareIcon classes={{ root: classes.icon }} style={{ marginRight: "0.2rem" }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>Terrain de {terrain} m²</Typography>
                        </Grid>
                    </Grid>) : (
                        <Grid item alignItems='center' container >
                        <Grid item>
                            <BarChart classes={{ root: classes.icon }} />
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontSize: "1rem" }}>De classe énergétique {diagnostic}</Typography>
                        </Grid>
                    </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}