import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import { makeStyles } from "@material-ui/styles"
import Divider from "@material-ui/core/Divider"


const useStyles = makeStyles(theme => ({
    titlesContainer: {
        width: "50rem",
    [theme.breakpoints.down('md')]: {
        width: '20rem'
    },
    // [theme.breakpoints.down('xs')]: {
    //     width: '15rem'
    // }
},
    // critereContainer: {
    //     width: '30rem'
    // },
    paylod: {
        width: '15rem'
    }
}))

export default function ({ title, price, city, piece, surfaceHabitable }) {
    const classes = useStyles()

    // const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    return (
        <Grid item container alignItems="center" classes={{root: classes.critereContainer}} justifyContent="center" direction="row">
            <Grid item container classes={{root: classes.titlesContainer}} direction="column">
                <Grid classes={{root: classes.paylod}} item>
                    <Typography>{title}</Typography>
                </Grid>
                <Grid item alignContent="center" classes={{root: classes.paylod}} spacing={1} alignItems="center" container>
                    <Grid item>
                        <Typography style={{fontSize: "0.85rem"}}>{piece} pièce{piece > 1 ? "s" : ""}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: "1.5rem", marginBottom: "0.55rem"}}>.</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: "0.85rem"}}>{surfaceHabitable} m²</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: "1.5rem", marginBottom: "0.55rem"}}>.</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: "0.85rem"}}>{city}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Chip label={`${price} €`} />
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
        </Grid>
    )
}