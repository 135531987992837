import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/styles"
import { Link } from "gatsby"

import Layout from "../components/ui/layout"
import AnnonceImages from "../components/annonce-detail/AnnonceImages"
import AnnonceInfo from "../components/annonce-detail/AnnonceInfo"

import Image1 from "../images/imgannonce1.jpg"
import Image2 from "../images/imgannonce2.jpg"
import Image3 from "../images/imgannonce3.jpg"

import { Button } from "@material-ui/core"
import AnnonceDescription from "../components/annonce-detail/AnnonceDescription"
import Critere from "../components/annonce-detail/critere"


const useStyles = makeStyles(theme => ({
    detailContainer: {
        width: "100%",
        marginTop: "5rem"
    },
    button: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        "&:hover":{
            color: theme.palette.secondary.light
        }
    }
}))





const AnnonceDetail = ({ pageContext }) => {
    const classes = useStyles()

    // const images = [
    //     pageContext.img,
    //     Image1,
    //     Image2,
    //     Image3,
    // ]
    const photos = pageContext.photos
    const imgs = photos.map(photo => {
        const item = photo.localFile.url
        // const img = {
        //     url: photo.localFile.url
        // }
        return item;
    });

    const images = imgs

    const [selectedImage, setSelectedImage] = useState(0)

    const next = (i, t) =>{
        if (i === t-1){
            setSelectedImage(0)
        }else{
            setSelectedImage(i+1)
        }
    }

    const previous = (i, t) => {
        if(i===0){
            setSelectedImage(t-1)
        } else {
            setSelectedImage(i-1)
        }
    }

    return (
        <Layout>
            <Grid container classes={{ root: classes.detailContainer }} alignItems="center" direction="column">
                <AnnonceImages next={next} previous={previous} images={images} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                <AnnonceInfo 
                title={pageContext.title} 
                price={pageContext.price} 
                piece={pageContext.piece} 
                surfaceHabitable={pageContext.surfaceHabitable}
                city={pageContext.city} />
                {/* <Grid item>
                    <Typography>{pageContext.id}</Typography>
                </Grid> */}
                <AnnonceDescription descDetaillee={pageContext.descDetaillee} brevDesc={pageContext.desc} />
                <Critere 
                piece={pageContext.piece} 
                type={pageContext.type} 
                surfaceHabitable={pageContext.surfaceHabitable}
                terrain={pageContext.terrain}
                chambre={pageContext.chambre}
                diagnostic={pageContext.diagnostic}
                city={pageContext.city} />
                <Grid item>
                    <Button component={Link} to="/contact" classes={{ root: classes.button }}>Nous contacter</Button>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default AnnonceDetail