import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles(theme => ({
    descriptionContainer: {
        width: "55rem",
        [theme.breakpoints.down('xs')]: {
            width: '25rem'
        }
    },
    descriptionTitleContainer: {
        marginTop: "1rem",
        borderLeft: `5px solid ${theme.palette.secondary.main}`
    },
    descriptionTitle: {
color: `${theme.palette.primary.main}`
    }
}))

export default function AnnonceDescription({ brevDesc, descDetaillee }) {
    const classes = useStyles()


    return(
        <Grid item container classes={{root: classes.descriptionContainer}} spacing={2} direction='column'>
            <Grid item classes={{root: classes.descriptionTitleContainer}}>
                <Typography variant='h1' classes={{root: classes.descriptionTitle}} style={{fontWeight: "bold"}}>Description</Typography>
            </Grid>
            <Grid item>
                <Typography>{brevDesc}</Typography>
            </Grid>
            <Grid item>
                <Typography style={{fontSize: "0.85rem"}}>{descDetaillee}</Typography>
            </Grid>
        </Grid>
    )
}