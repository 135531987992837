import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles, } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Carousel from 'better-react-carousel'
import { Typography } from '@material-ui/core'

import ArrowLeft from "@material-ui/icons/ArrowBackIos"
import ArrowRight from "@material-ui/icons/ArrowForwardIos"


const useStyles = makeStyles(theme => ({
    imgSelected: {
        // width: "35rem",
        maxWidth: "50rem",
        height: "auto",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "30rem",
            // width: '25rem',
            // height: '17rem',
            height: 'auto'
        }
    },
    smallImg: {
        width: "5rem",
        height: "4rem",
        [theme.breakpoints.down('sm')]: {
            height: '4rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '4rem',
            height: '3rem'
        }
    },
    smallImgRow: {
        marginLeft: "1rem",
        width: "5rem",
        height: "25rem",
        maxWidth: "5rem",
        // maxHeight: "25rem",
        overflow: "auto",
        [theme.breakpoints.down('sm')]: {
            width: '36rem',
            maxWidth: "36rem",
            overflow: 'auto',
            height: '5rem',
            marginLeft: '0'
        },
        [theme.breakpoints.down('xs')]: {
            width: '25rem',
            marginLeft: '0',
            maxWidth: "25rem",
            overflow: 'auto',
        }
    }
}))

export default function AnnonceImages({ images, previous, next, selectedImage }) {
    const classes = useStyles()

    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    console.log(selectedImage)

    return (
        <Grid
            item
            container
            justifyContent='center' alignItems='center' direction={matchesSM ? 'column' : 'row'}>
                <Grid item>
                    <Button disabled={selectedImage===0} onClick={() => previous(selectedImage, images.length)}>
                        <ArrowLeft />
                    </Button>
                </Grid>
            <Grid item>
                <img src={images[selectedImage]} className={classes.imgSelected} alt='photo du bien' />
                {/* <Carousel cols={1} style={{ marginBottom: "2rem" }} rows={1} loop>
                        {images
                            .map((item, i) => (
                                <Carousel.Item key={i}>
                                    <img src={item}  />
                                </Carousel.Item>
                            ))}
                    </Carousel> */}
            </Grid>
            <Grid item>
                    <Button disabled={selectedImage===images.length-1} onClick={() => next(selectedImage, images.length)}>
                        <ArrowRight />
                    </Button>
                </Grid>
        </Grid>
    )
}